import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import LoadingButton from 'components/LoadingButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from 'components/Accordion'
import TextField from 'components/TextField'
// eslint-disable-next-line import/no-extraneous-dependencies
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { useGlobalContext } from 'store/global'
import { useTheme } from '@mui/material/styles'
import SETTINGS from 'components/Settings/constData.json'
import Switch from 'components/Switch'
import Stack from '@mui/material/Stack'
import Loader from 'components/Loader'
import { ENV, GET_CONFIG_ROUTE } from 'constants/routes'

import { updateInfo } from 'helpers/fetchUtil'
import StatusMessages from 'components/StatusMessages'

import { useAuthState } from 'store/user'
import { EditPen } from 'components/Icons'

import useStyles from '../Shipments/styles'

const Reviews = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { device } = useGlobalContext()
  const [reviewsActivated, setReviewsActivated] = useState(false)
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [query, setQuery] = useState()
  const [queryIndex, setQueryIndex] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [placeId, setPlaceId] = useState(null)
  const [placeSelected, setPlaceSelected] = useState(null)
  const [addressSelected, setAddressSelected] = useState(null)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { storeConfig } = useAuthState()

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,
    debounce: SETTINGS.searchDebounceDelay,
    language: SETTINGS.lang,
    options: {
      componentRestrictions: { country: SETTINGS.bounds.country },
      types: SETTINGS.bounds.types,
    },
  })

  const handleSelect = (data) => {
    setPlaceId(data.place_id)
    setPlaceSelected(data.name)
    setAddressSelected(data.formatted_address)
    setQuery('')
    setQueryIndex('')
  }

  useEffect(() => {
    if (placePredictions.length && placePredictions[queryIndex]) {
      placesService?.getDetails(
        {
          placeId: placePredictions[queryIndex].place_id,
        },
        (placeDetails) => handleSelect(placeDetails)
      )
    }
  }, [queryIndex])

  useEffect(() => {
    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.google_reviews &&
      storeConfig.store_settings.google_reviews.active
    ) {
      setReviewsActivated(storeConfig.store_settings.google_reviews.active)
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.google_reviews &&
      storeConfig.store_settings.google_reviews.place_id
    ) {
      setPlaceId(storeConfig.store_settings.google_reviews.place_id)
      placesService?.getDetails(
        {
          placeId: storeConfig.store_settings.google_reviews.place_id,
        },
        (placeDetails) => handleSelect(placeDetails)
      )
      setIsEdit(true)
    }
  }, [storeConfig, placesService])

  const handleCancel = () => {
    setExpandedPanel((prev) => !prev)
  }

  const handleChangeAccordion = () => {
    if (!expandedPanel) {
      cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
        Click: PV.resenas,
        Device: device.type,
      })
    }
  }

  const handleSave = (ra) => {
    setLoading(true)

    const payload = {
      store_settings: {
        google_reviews: {
          active: ra,
          place_id: placeId,
        },
      },
    }

    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, payload)
      .then(() => {
        setSubmitSuccess(true)
        setIsEdit(true)
        cleverTap.event(EVENTS.CONFIGURACION_VIEWED, {
          Click: PV.guardar,
          Device: device.type,
        })
        setLoading(false)
      })
      .catch(() => {
        setSubmitError(true)
        setLoading(false)
      })
  }

  const handleClickSwitch = () => {
    setReviewsActivated(!reviewsActivated)
    handleSave(!reviewsActivated)
  }

  const handleEdit = () => {
    setIsEdit(false)
    setPlaceSelected(null)
  }

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  return (
    <Accordion expanded={expandedPanel} onChange={handleChangeAccordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        onClick={handleCancel}
      >
        <Typography align="center" variant="h2">
          {SETTINGS.REVIEWS_TITLE}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography
            color="text.light"
            fontWeight={theme.fontWeight.bold}
            sx={{
              pb: 3,
              width: '100%',
            }}
            variant="body1"
          >
            {reviewsActivated
              ? SETTINGS.REVIEWS_ACTIVATED
              : SETTINGS.REVIEWS_DESCRIPTION}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography color="text.light" fontWeight={theme.fontWeight.bold}>
              {reviewsActivated && SETTINGS.ACTIVATED}
            </Typography>
            <Switch
              checked={reviewsActivated}
              disabled={!placeId}
              inputProps={{ 'data-testid': 'switch-activate-reviews' }}
              onClick={handleClickSwitch}
              sx={{ mr: `${theme.space[4]}px` }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: 'center', maxWidth: { md: '40%', sm: '100%' } }}
        >
          {!isEdit ? (
            <TextField
              autoComplete="off"
              fullWidth
              InputProps={{
                'data-testid': 'input-reviews',
                endAdornment: isPlacePredictionsLoading && (
                  <Stack
                    sx={{
                      '& .MuiContainer-root': { padding: 0, width: 'initial' },
                    }}
                  >
                    <Loader />
                  </Stack>
                ),
              }}
              label={SETTINGS.REVIEWS_INPUT_PH}
              onChange={(e) => {
                getPlacePredictions({ input: e.target.value })
                setQuery(e.target.value)
                setPlaceSelected(null)
                setAddressSelected(null)
              }}
              value={!query && placeSelected ? placeSelected : query}
              variant="filled"
            />
          ) : (
            <Stack>
              <Typography>{placeSelected}</Typography>
              <Typography>{addressSelected}</Typography>
            </Stack>
          )}
          {isEdit ? (
            <Button
              color="secondary"
              onClick={handleEdit}
              size="small"
              startIcon={
                <EditPen color={theme.palette.primary.main} size={2} />
              }
              sx={{ marginRight: { md: '24px', sm: '6px' } }}
            >
              {SETTINGS.EDIT_BUTTON}
            </Button>
          ) : (
            <LoadingButton
              color="orange"
              disabled={!placeSelected}
              loading={loading}
              onClick={() => handleSave(reviewsActivated)}
              size="small"
            >
              {SETTINGS.SAVE_BUTTON}
            </LoadingButton>
          )}
        </Stack>
        {!isPlacePredictionsLoading && query && (
          <List>
            {placePredictions.map((item, index) => (
              <Stack key={item.description}>
                <ListItem
                  onClick={() => setQueryIndex(index)}
                  sx={{ pl: 0, pr: 0 }}
                >
                  <Button
                    className="suggest"
                    fullWidth
                    sx={{ justifyContent: 'flex-start', textAlign: 'left' }}
                  >
                    <Typography variant="body0">{item.description}</Typography>
                  </Button>
                </ListItem>
              </Stack>
            ))}
          </List>
        )}
      </AccordionDetails>
      <StatusMessages
        errorFlag={submitError}
        errorMessage={SETTINGS.ERROR_MESSAGE}
        handleClose={() => handleClose()}
        successFlag={submitSuccess}
        successMessage={SETTINGS.SUCCESS_MESSAGE}
      />
    </Accordion>
  )
}

export default Reviews
