import { ENV } from 'constants/routes'

const validateEmail = (value) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)

const validateWhatsapp = (value) => !/^[0-9 -+]/g.test(value)

const onlyNumber = (value) => !/[0-9]/g.test(value)

const validateTime = (time) => {
  const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

  return time.match(timeReg)
}

const parsePrice = (price) =>
  parseFloat(price.replace('$', '').replace(',', ''))

const getTimeMask = (time = '') => [
  /[0-2]/,
  time[0] === '2' ? /[0-3]/ : /[0-9]/,
  ':',
  /[0-5]/,
  /[0-9]/,
]

const validateAlias = (aliasString) => {
  const alias = aliasString.split(`${ENV.REACT_APP_STORE_BASE_URL}/`)[1]

  if (!alias) {
    return false
  }

  return alias
}

const convertToValidURL = (value) =>
  value
    .replaceAll(' ', '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '')

const allowedHosts = [
  'facebook.com',
  'www.facebook.com',
  'instagram.com',
  'www.instagram.com',
  'tiktok.com',
  'www.tiktok.com',
  'youtube.com',
  'www.youtube.com',
]

const validateSocialNetworkUrl = (value) => {
  const sanitizedHost = new URL(value).host

  return allowedHosts.includes(sanitizedHost)
}

export {
  onlyNumber,
  parsePrice,
  validateAlias,
  validateEmail,
  validateWhatsapp,
  validateTime,
  getTimeMask,
  convertToValidURL,
  validateSocialNetworkUrl,
}
